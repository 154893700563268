<template>
  <div class="bgimg">
    <nav-bar></nav-bar>
    <div class="main">
       <div class="ovh">
         <div class="bigword">
           让人们<br> 因你的价值<br> 喝彩
         </div>
         <div class="search">
           <input type="text" placeholder="   手机号">
           <input class="mt-22" type="password" placeholder="   密码">
         </div>
       </div>
       <div class="ovh">
         <div class="smallword">
            <span>飞观是商业和思想的视频严选订阅平台</span>，通过严选全球有价值的视频内容,帮助用户在信息粉尘时代，提升信息获取质量和效
            率，启迪思想，升级认知。<br><br>
            邀请拥有优质商业或思想内容创作能力的创作者入驻飞观。
         </div>
         <div class="btn">
           <div class="btn-r">登录</div>
           <div class="btn-l" @click="$router.push('/settle')">申请入驻</div>
         </div>
       </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import NavBar from '@/components/nav.vue'
import Foot from '@/components/foot.vue'
export default {
  data () {
    return {
    }
  },
  components: {
    NavBar,
    Foot
  }
}
</script>

<style scoped>
.bgimg {
    background-image: url(./status/loginbanner.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 24px;
}
.main {
    width: 1160px;
    margin: 0 auto;
    overflow: hidden;
}
.bigword {
    width: 310px;
    height: 261px;
    margin-top: 90px;
    margin-bottom: 31px;
    float: left;
    font-size:62px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:800;
    color:rgba(255,255,255,1);
    line-height:87px;
    text-align: left;
}
.search {
    width: 446px;
    height: 142px;
    margin-top: 188px;
    float: right;
}
.search input {
    width: 442px;
    height: 60px;
    border-radius: 6px;
}
.search input::-webkit-input-placeholder {
    font-size:18px;
    font-family:PingFang-SC-Medium,PingFang-SC;
    font-weight:500;
    color:rgba(0,0,0,1);
    line-height:25px;
}
.mt-22 {
    margin-top: 22px;
}
.smallword {
    width: 500px;
    height: 125px;
    float: left;
    margin-bottom: 84px;
    font-size:18px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:800;
    color:rgba(255,255,255,1);
    line-height:25px;
    text-align: justify;
}
.smallword span {
    font-size:18px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:500;
    color:rgb(240, 240, 240);
}
.btn {
    width: 314px;
    height: 54px;
    float: right;
}
.btn .btn-r,.btn-l {
    width: 149px;
    height: 39px;
    border-radius: 9px;
    padding-top: 17px;
    font-size:18px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:800;
    color:rgba(255,255,255,1);
    cursor: pointer;
}
.btn-r {
    float: right;
    background-color: #F82828;
}
.btn-r:hover {
    background-color: #FF3E3E;
}
.btn-r:active {
    background-color: #8A0909;
}
.btn-l {
    float: left;
    background-color: #fff;
    color: #000;
}
.btn-l:hover {
    background-color: #F7F7FA;
}
.btn-l:active {
    background-color: #EAEAEA;
}
/* 1200--992 */
@media screen and (max-width:1200px){
    .main {
        width: 878px;
    }
}
/* 992-768 */
@media screen and (max-width:992px){
    .bgimg {
        padding-top: 0;
    }
    .main {
        width: 100%;
    }
    .bigword {
        width: 446px;
        float:none;
        margin: 103px auto 30px;
    }
    .search {
        width: 446px;
        margin-top: 0;
        float: none;
        margin: 0 auto;
    }
    .search input {
        width: 446px;
    }
    .btn {
        width: 314px;
        float: none;
        margin: 46px auto;
    }
    .smallword {
        display: none;
    }
}
/* 768-576 */
@media screen and (max-width:768px) {
    .bigword {
        width: 77%;
    }
    .search {
        width: 77%;
    }
    .search input {
        width: 100%;
    }
    .btn {
        width: 54%;
    }
    .btn .btn-r,.btn-l {
        width: 48%;
    }
    .bigword {
        font-size: 44px;
    }
}
@media screen and (max-width: 576px) {
    .btn .btn-r,.btn-l {
        font-size: 14px;
        height: 35px;
        padding-top: 17px;
    }
    .bigword {
        font-size: 36px;
    }
}
</style>
