<template>
  <div>
    <div class="nav ovh">
      <div class="nav-main">
        <div class="fl logo">
          <img src="./status/logo-header.png" alt />
        </div>
        <div class="fl public" @click="$router.push('/')">首页</div>
        <div class="fl public" @click="$router.push('/download')">下载</div>
        <div class="fr public" @click="$router.push('/settle')" style="margin-right:60px">平台入驻</div>
        <div class="fr public" @click="$router.push('/login')">投稿中心</div>
      </div>
    </div>
    <div class="mobile-nav">
      <van-dropdown-menu>
        <van-dropdown-item>
          <template slot="title">
            <div class="drop">
              <img src="./status/logo-header.png" alt />
              <i class="iconfont">&#xe654;</i>
            </div>
          </template>
          <div class="drop-item" @click="$router.push('/')">
            <i class="iconfont">&#xe6a5;</i>
            <span>首页</span>
          </div>
          <div class="drop-item" @click="$router.push('/download')">
            <i class="iconfont">&#xe6ac;</i>
            <span>下载</span>
          </div>
          <div class="drop-item" @click="$router.push('/login')">
            <i class="iconfont">&#xe663;</i>
            <span>投稿</span>
          </div>
          <div class="drop-item" @click="$router.push('/settle')">
            <i class="iconfont">&#xe617;</i>
            <span>入驻</span>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
  </div>
</template>

<script>
window.addEventListener('scroll', function () {
  if (window.pageYOffset > 675) {
    document.querySelector('.nav').style.position = 'fixed'
    document.querySelector('.nav').style.top = '0'
    document.querySelector('.nav').style.width = '100%'
    document.querySelector('.nav').style.borderRadius = '0'
  } else {
    document.querySelector('.nav').style.position = ''
    document.querySelector('.nav').style.top = ''
    document.querySelector('.nav').style.width = ''
    document.querySelector('.nav').style.borderRadius = ''
  }
})

export default {
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.nav,.nav-main {
  width: 1160px;
  height: 60px;
  background-color: white;
  border-radius: 11px;
  margin: 0 auto;
}

.public {
  width: 100px;
  height: 60px;
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 60px;
  cursor: pointer;
}
.public:hover {
  background-color: #f82828;
  color: rgba(255, 255, 255, 1);
}
.logo {
  width: 100px;
  height: 60px;
}
.logo img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.mobile-nav {
  display: none;
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 999;
}
.drop {
  width: 100%;
  height: 60px;
  background-color: #f7f7fa;
  text-align: right;
  cursor: pointer;
}
.drop img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.drop i {
  display: block;
  font-size: 26px;
  color: rgba(248, 40, 40, 1);
  padding: 16px 32px;
  cursor: pointer;
  background-color: #f7f7fa;
}
.drop-item {
  color: #000;
  padding: 20px 38px;
  border-bottom: 1px solid #e8eef0;
  cursor: pointer;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
}
.drop-item i {
  font-size: 24px;
}
.drop-item span {
  display: inline-block;
  font-size: 18px;
  margin-left: 31px;
}

/* 1200--992 */
@media screen and (max-width: 1200px) {
  .nav,.nav-main {
    width: 878px;
  }
}
/* 992-768 */
@media screen and (max-width: 992px) {
  .nav {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
}
</style>
