<template>
    <footer>
      <div class="foot-main">
        <div class="foot-l">
           <img src="./status/logo-white.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
           <div class="foot-l-top">飞观 | 商业和思想的视频严选订阅平台</div>
           <div class="foot-l-bottom">© 2019 <a href="http://itso.tv" target="_blank">北京如是纪录文化传媒有限公司</a></div>
        </div>
        <div class="foot-c">
           <div class="foot-c-top">联系我们</div>
           <div class="foot-c-mid"><span class="buspub">商务合作</span> <span>wangshun@itso.tv</span></div>
           <div class="foot-c-bot"><span class="buspub">UP主入驻</span> wangshun@itso.tv</div>
        </div>
        <div class="foot-r">
           <div class="foot-r-top"></div>
           <div class="foot-r-mid"><a href="http://www.beian.miit.gov.cn/" target="_blank">京ICP备19016999号-1</a></div>
           <div class="foot-r-bot ovh">
                <el-popover
                   trigger="click"
                   placement="top-start">
                    <img src="./status/qcode1.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
                    <p class="tipword">打开微信扫一扫</p>
                    <div slot="reference" class="fl  wechat-btn">
                      <i class="iconfont wechat">&#xe616;</i>
                    </div>
                </el-popover>
               <div class="fl line"></div>
               <div class="fl weibo-btn"><a href="http://weibo.com/u/6523821969" target="_blank"><i class="iconfont weibo">&#xe63d;</i></a></div>
           </div>
        </div>
      </div>
    </footer>
</template>
<script>
export default {

}
</script>

<style scoped>
  footer {
    width: 100%;
    background-color: rgba(54,54,54,1);
}
.foot-main {
    width: 1160px;
    height: 299px;
    margin: 0 auto;
    overflow: hidden;
}
.foot-l,.foot-c,.foot-r {
    float: left;
    width: 33.33%;
    height: 299px;
}
.foot-l img {
    width: 82px;
    height: 78px;
    margin-top: 67px;
    margin-bottom: 26px;
    margin-right: 301px;
}
.foot-l .foot-l-top {
    font-size:16px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:800;
    color:rgba(255,255,255,1);
    text-align: left;
    margin-bottom: 18px;
}
.foot-l .foot-l-bottom {
    font-size:16px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:800;
    color:rgba(255,255,255,1);
    text-align: left;
}
.foot-l-bottom a {
    color: #fff;
}
.foot-c-top {
    font-size:16px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:800;
    color:rgba(255,255,255,1);
    margin-top: 81px;
    text-align: left;
    margin-bottom: 32px;
}
.foot-c-mid {
    font-size:16px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:800;
    color:rgba(255,255,255,1);
    text-align: left;
    margin-bottom: 18px;
}
.foot-c-bot {
    font-size:16px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:800;
    color:rgba(255,255,255,1);
    text-align: left;
}
.buspub {
    display: inline-block;
    width: 74px;
}
.foot-r-top {
    font-size:16px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:800;
    color:rgba(255,255,255,1);
    text-align: right;
    margin: 87px auto 18px;
}
.foot-r-mid {
    font-size:16px;
    font-family:PingFang-SC-Heavy,PingFang-SC;
    font-weight:800;
    color:rgba(255,255,255,1);
    text-align: right;
    margin: 0 auto 31px;
}
.foot-r-mid a {
    color: #fff;
}
.foot-r-bot {
    width: 200px;
    height: 49px;
    background:rgba(255,255,255,1);
    border-radius:28px;
    margin-left: 187px;
}
.wechat-btn {
    width: 99px;
    height: 49px;
    cursor: pointer;
    background-color: #fff;
}
.bg-white {
    background-color: #fff !important;
}
.tipword {
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    color: rgb(253, 243, 243);
}
.wechat {
    display: block;
    font-size: 24px;
    color:rgba(54,54,54,1);
    margin: 12px 31px 0 44px;
}
.line {
    width: 2px;
    height: 31px;
    background: #363636;
    margin: 9px 10px 0 0;
}
.weibo-btn {
    width: 68px;
    height: 49px;
    cursor: pointer;
}
.el-popover {
    background-color: #fff !important;
    border-radius: 7px;
    margin-bottom: 4px;
}
#el-popover-737 {
    background-color: #fff;
}
.el-popover img {
    width: 157px;
    height: 157px;
    margin: 4px;
}
.weibo {
    display: block;
    color:rgba(54,54,54,1);
    font-size: 24px;
    margin-top: 12px;
}

/* 1200--992 */
@media screen and (max-width:1200px){
    .foot-main {
        width: 878px;
        height: 502px;
    }
    .foot-l,.foot-c {
        float: left;
        width: 50%;
        height: 299px;
    }
    .foot-l img {
        margin-right: 225px;
    }
    .foot-l-top,.foot-l-bottom {
        margin-left: 72px;
    }
    .foot-c-top,.foot-c-mid,.foot-c-bot {
        margin-left: 68px;
    }
    .foot-r {
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
    .foot-r-top,.foot-r-mid {
        text-align: center;
    }
    .foot-r-top {
        margin-top: 0;
    }
    .foot-r-bot {
        margin-left: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
/* 992-768 */
@media screen and (max-width:992px){
    footer {
        width: 100%;
    }
    .foot-main {
        width: 300px;
        height: 635px;
    }
    .foot-l,.foot-c,.foot-r {
        width: 100%;
    }
    .foot-l {
        height: 280px;
    }
    .foot-c {
        height: 150px;
    }
    .foot-l img {
        margin: 57px auto 29px;
    }
    .foot-l-top,.foot-l-bottom  {
        margin: 0 auto;
    }
    .foot-c-top {
        margin: 0px auto 26px;
        text-align: center;
    }
    .foot-c-mid {
        margin: 0 auto 12px;
    }
    .foot-c-bot {
        margin: 0 auto;
    }
}
/* 768-576 */
@media screen and (max-width:576px) {
   .foot-l .foot-l-top,.foot-l .foot-l-bottom,.foot-c-top,.foot-c-mid,.foot-c-bot,.foot-r-top,.foot-r-mid {
       width: 74%;
       font-size: 12px;
   }
}

</style>
